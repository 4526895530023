import { FC, useMemo } from 'react';
import { ColumnConfig } from '../../../../models/TableView';
import { TemplateFilterValue } from '../../../../models/TableViewFilters';
import Badge from '../../../shared/badge/Badge';
import { ModuleFormTemplatesFilterQuery } from '../TemplateFormFilter';
import { useTableView } from '../../../../contexts/table-view/TableViewContext';
import { useQuery } from '@apollo/client';
import LanguageUtils from '../../../../utils/LanguageUtils';

const TemplateFormFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter } = props;
  const templateFormFilters = filter as TemplateFilterValue | undefined;
  const { clientModuleId, templateModuleSectionId } = useTableView();

  const { data: templateFormData } = useQuery(ModuleFormTemplatesFilterQuery, {
    variables: {
      cmId: clientModuleId,
      tmSectionId: templateModuleSectionId || undefined,
    },
  });

  const titles = useMemo(() => {
    const data = templateFormData?.templateFormsByClientModule?.nodes ?? [];
    const result: Record<string, string> = {};

    for (const template of data) {
      result[template!.id as string] = LanguageUtils.getTranslation('title', template?.translations || {});
    }

    return result;
  }, [templateFormData?.templateFormsByClientModule?.nodes]);

  return (
    <div className="flex flex-wrap items-center gap-2">
      {templateFormFilters?.map((id) => (
        <div key={id}>
          <Badge textClass="text-dpm-gray-2 !font-normal" text={titles[id as string]} />
        </div>
      ))}
    </div>
  );
};

export default TemplateFormFilterOverview;
